ul[data-v-a51ae8d0] {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul > li[data-v-a51ae8d0] {
  display: inline;
}
ul > li[data-v-a51ae8d0]:not(:last-child)::after {
  content: '/';
  font-size: 12px;
  color: grey;
  padding: 0 3px;
}
