li > .category-name[data-v-103a97c1] {
  cursor: pointer;
  margin-left: -300px;
  padding-left: 300px;
}
li > .category-name[data-v-103a97c1]:hover {
  background-color: lightblue;
  color: #000 !important;
}
li.active > .category-name[data-v-103a97c1] {
  background-color: lightskyblue;
  font-weight: 700;
  text-decoration: underline;
}
li.active > .category-name[data-v-103a97c1]:not(.text-muted) {
  color: #000;
}
li > ul[data-v-103a97c1] {
  list-style-type: none;
  padding-left: 20px;
}
