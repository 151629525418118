#product-navi-container[data-v-0713d1d7] {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#product-navi-container[data-v-0713d1d7] > :first-child,
#product-navi-container[data-v-0713d1d7] :last-child {
  cursor: pointer;
}
.photo[data-v-0713d1d7] {
  display: inline-flex;
  margin-left: 2px;
  margin-bottom: 2px;
}
.photo > a[data-v-0713d1d7]:hover {
  z-index: 99;
}
.variant-photo[data-v-0713d1d7] {
  width: 100px;
  border-radius: 4px;
}
.main-photo[data-v-0713d1d7] {
  height: 150px;
  border-radius: 8px;
}
.zoom[data-v-0713d1d7] {
  transition: transform 0.5s;
}
.zoom[data-v-0713d1d7]:hover {
  transform: scale(2);
}
